body {
	font-family: "Times New Roman", Times, serif;
	margin: 0;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background-color: #f4f4f4;
}

.container {
	max-width: 600px;
	width: 100%;
	text-align: center;
	background-color: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

#uploadButton {
	padding: 10px 20px;
	background-color: #007bff;
	color: #fff;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	transition: background-color 0.3s ease;
}

#uploadButton:hover {
	background-color: #0056b3;
}

#fileInput {
	margin-top: 5px;
	margin-right: 10px;
}

#uploadForm {
	display: flex;
	flex-direction: column;
	align-items: center;
}

label {
	font-size: 20px;
	margin-bottom: 5px;
}

img {
	max-width: 150px; /* Keep the image size the same */
	margin-bottom: 20px;
}
